import React from 'react'
import Section from './Section'
import SectionHeading from './SectionHeading'
import InputElement from './InputElement'

const ContactUs = (Props) => {
  return (
    <div style={{ backgroundColor: `#f1f1f1` }}>

      <Section>
        <SectionHeading>
          Contact Us
        </SectionHeading>
        <form action="" className='w-100 d-flex flex-column justify-content-center align-items-center' >
          <InputElement>
            <label htmlFor="name" className='py-1'>Name <span style={{color:`red`}}>*</span></label>
            <input type='text' name='name' className='p-1' style={{
              padding: '0.5rem',
              fontSize: `0.9rem`,
              width: '100%',
              borderRadius: '4px',
              border: '1px solid #ccc',
              outline: 'none',
              marginTop: '0.25rem',
            }} required></input>
          </InputElement>
          <InputElement>
            <label htmlFor="email" className='py-1'>Email <span style={{color:`red`}}>*</span></label>
            <input type='email' name='email' className='p-1' style={{
              padding: '0.5rem',
              fontSize: `0.9rem`,
              width: '100%',
              borderRadius: '4px',
              border: '1px solid #ccc',
              outline: 'none',
              marginTop: '0.25rem',
            }} required></input>
          </InputElement>
          <InputElement>
            <label htmlFor="number" className='py-1'>Contact</label>
            <input type='text' name='number' className='p-1' style={{
              padding: '0.5rem',
              fontSize: `0.9rem`,
              width: '100%',
              borderRadius: '4px',
              border: '1px solid #ccc',
              outline: 'none',
              marginTop: '0.25rem',
            }}></input>
          </InputElement>
          <InputElement>
            <label htmlFor="note" className='py-1'>Comments</label>
            <textarea type='text' name='note' className='p-1' style={{
              padding: '0.5rem',
              width: '100%',
              fontSize: `0.9rem`,
              borderRadius: '4px',
              border: '1px solid #ccc',
              outline: 'none',
              marginTop: '0.25rem',
              resize: 'vertical',
            }}></textarea>
          </InputElement>
          <button className='w-25 py-1 my-4' style={{
            width: '25%',
            padding: '0.5rem',
            marginTop: '1rem',
            marginBottom: '1rem',
            backgroundColor: '#1565C0',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontWeight: 'bold',
            transition: 'background-color 0.3s ease',
          }}
            onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#1565C0'}
          >Submit</button>

        </form>
      </Section>
    </div>
  )
}

export default ContactUs