import React from 'react'
import Section from './Section'
import SpaceBetween from './SpaceBetween'
import footerImg from '../assets/footerImg.jpg'
import Logo from '../assets/oldLogo.png'
import FooterSvg from './FooterSvg'
import SocialBtn from './SocialBtn'
import whatsapp from '../assets/whatsapp.png'
import telegram from '../assets/telegram.png'
import linkedin from '../assets/linkedin.png'


const Footer = (Props) => {
  return (
    <div className='d-flex align-items-center flex-column justify-content-center pt-5 position-relative'>
      <img className='my-5' src={`${Logo}`} style={{ height: `4rem` }} alt='Sunstrat-Brand-Image'></img>
      <p>High level experience in Solar Designing.</p>
      <div className='d-flex mt-4'>
        <SocialBtn social={whatsapp} socialName={"whatsapp"}/>
        <SocialBtn social={telegram} socialName={"telegram"}/>
        <SocialBtn social={linkedin} socialName={"linkedin"}/>
      </div>
      <div style={{ position: `absolute`, top: `22rem`, width: `100%`, overflow: `hidden`, lineHeight: `0`}}>
        <FooterSvg />
      </div>
      <p style={{position:`absolute`, bottom:`-12rem`, color:`white`}}>© 2023 All Rights Reserved</p>
    </div>
  )
}

export default Footer