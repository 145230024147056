import React from 'react'

function Section(Props) {
  return (
    <div className='px-5 d-flex align-items-center justify-content-center flex-column py-2 h-100 z-2 container'>
        {Props.children}
    </div>
  )
}

export default Section