import React from 'react'

const SocialBtn = (props) => {
    const redirectTo = (social) => {
        console.log(social);
        
        switch(social) {
            case "telegram":
                window.open("https://t.me/A_huzaifa", "_blank");
                break;
            case "whatsapp":
                window.open("https://wa.me/9619407861", "_blank");
                break;
            case "linkedin":
                window.open("https://www.linkedin.com/company/sunstratsolutions/", "_blank");
                break;
            default:
                console.log("No matching platform found.");
        }
    }

    return (
        <div className='mx-3 d-flex justify-content-center align-items-center box-hover-effect border' style={{ width: `2.5rem`, height: `2.5rem`,borderRadius: `50%`}} onClick={() => redirectTo(props.socialName)}>
            <img src={`${props.social}`} alt={`${props.socialName}`} style={{
                width: '70%',
                height: '70%',
                objectFit: 'cover',
                alignItems:`center`
            }}
            />
        </div>
    )
}

export default SocialBtn